import { css } from "@emotion/react";
import rightCurve from "../../../images/compliance/Compliance_1.svg";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 670px;
  }
  .curves-parent {
    background: url(${rightCurve}) center no-repeat;
    width: 1865px;
    height: 622px;
    transform: translate(-74%, 16%);
  }
  .title-lg-parent {
    max-width: 620px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 1279.98px) {
    .curves-parent {
      transform: translate(-61%, 27%);
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 480px;
    }

    .curves-parent {
      transform: translate(-64%, 95%);
    }
  }

  @media screen and (max-width: 480px) {
    .curves-parent {
      transform: translate(-54%, 100%);
    }
  }
`;

export { MainSectionWrapper };
