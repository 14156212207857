import React, { FC } from "react";
import { MainSectionWrapper } from "./sanctionsSection.styles";
import MainImage from "../../../images/compliance/Compliance_2.inline.svg";
import MainMobileImage from "../../../images/compliance/Compliance_2_Mobile.inline.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";
interface Props {}

const listData: CtaListProps = {
  title: ["Sanctions screening", "and adverse Media"],
  subTitle:
    "Integrated with best-in class data providers On-going screening and monitoring. PEP, SDN, and OFAC.",
};

const SanctionsSection: FC<Props> = () => {
  return (
    <section
      className="relative overflow-visible section-wrapper"
      css={MainSectionWrapper}
    >
      <div className="absolute top-0 curves-parent left-full"></div>
      <div className="relative w-full pt-24 bg-transparent wrapper-parent layout-width">
        <div className="flex flex-row-reverse flex-wrap justify-between h-full lg:mb-20">
          <div className="flex items-center justify-center w-1/2 sm:w-full">
            <CtaList {...listData} />
          </div>
          <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full">
            <div className="mx-auto">
              <MainImage className="md:hidden block" />
            </div>
            <MainMobileImage className="hidden md:block transform -translate-x-5 mt-12" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SanctionsSection;
