import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo/seo';
import AmlSection from '../components/complianceSections/amlSection/amlSection';
import SanctionsSection from '../components/complianceSections/sanctionsSection/sanctionsSection';
import KycSection from '../components/complianceSections/kycSection/kycSection';
import BlueSection from '../components/blueSetion/blueSection';

const CompliancePage = () => {
  const textArr = ['Move money', ' fast, ', 'without risk.'];
  return (
    <Layout>
      <Seo title='Compliance' pathname='/compliance' />
      <main>
        <AmlSection />
        <SanctionsSection />
        <KycSection />
        <BlueSection textArr={textArr} btnText={'Book a demo'} />
      </main>
    </Layout>
  );
};

export default CompliancePage;
