import { css } from "@emotion/react";
import leftCurves from "../../../images/left_curves.svg";
import compliane2MobileCurves from "../../../images/compliance/Compliance_2_Mobile_Curves.svg";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 550px;
  }
  .curves-parent {
    background: url(${leftCurves}) center no-repeat;
    width: 1865px;
    height: 822px;
    transform: translate(-130%, -30%);
  }
  .title-lg-parent {
    max-width: 550px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 1440px) {
    .curves-parent {
      transform: translate(-96%, -30%);
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 360px;
    }

    .curves-parent {
      left: 0;
      top: 0;
      background-size: cover;
      background-position: right;
      background: url(${compliane2MobileCurves}) right no-repeat;
      transform: none;
      width: 100%;
      height: 480px;
    }
  }

  @media screen and (max-width: 480px) {
    .image-parent svg {
      max-width: 100%;
    }
  }
`;

export { MainSectionWrapper };
