import React, { FC } from 'react';
import { MainSectionWrapper } from './amlSection.styles';
import straight from '../../../images/straight.svg';
import CtaList, { CtaListProps } from '../../ctaList/ctaList';
interface Props {}

const listData: CtaListProps = {
  title: ["Transaction monitoring", "for AML compliance"],
  subTitle:
    'Comes bundled with 100’s of pre-built and tested typologies, so you don’t need to spend money on regulatory consultants.',
  items: [
    {
      title: 'Fiat deposit and withdrawals',
      subTitle:
        'IRS $10K reporting avoidance, structuring, layering, and shared identities.',
      icon: straight
    },
    {
      title: 'Physical or virtual card issuance',
      subTitle: 'Support PSD2 and Strong Customer Authentication (SCA)',
      icon: straight
    },
    {
      title: 'Crypto send and receive',
      subTitle:
        'Comply with FATF Travel Rule, BitLicense. Integrates Coinbase Analytics.',
      icon: straight
    }
  ]
};

const AmlSection: FC<Props> = () => {
  return (
    <section className='relative section-wrapper' css={MainSectionWrapper}>
      <div className='absolute top-0 curves-parent left-full'></div>
      <div className='relative w-full pt-24 bg-transparent wrapper-parent layout-width'>
        <div className='flex flex-row flex-wrap justify-between h-full lg:mb-20'>
          <div className='flex justify-center w-1/2 sm:w-full'>
            <CtaList {...listData} />
          </div>
          <div className='flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full h-96'></div>
        </div>
      </div>
    </section>
  );
};

export default AmlSection;
