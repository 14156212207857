import { css } from "@emotion/react";
import rightCurve from "../../../images/compliance_right_curves.svg";
import compliane3MobileCurves from "../../../images/compliance/Compliance_3_Mobile_Curves.svg";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 753px;
  }
  .curves-parent {
    background: url(${rightCurve}) center no-repeat;
    width: 1865px;
    height: 622px;
    transform: translate(-72%, 30%);
  }
  .title-lg-parent {
    max-width: 620px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 767.98px) {
    .image-parent svg {
      max-width: 480px;
    }

    .curves-parent {
      left: 0;
      top: -135px;
      background-size: cover !important;
      background-position: left top;
      background: url(${compliane3MobileCurves}) right no-repeat;
      transform: none;
      width: 100%;
      height: 480px;
    }
  }

  @media screen and (max-width: 480px) {
    .image-parent svg {
      max-width: 100%;
    }
  }
`;

export { MainSectionWrapper };
