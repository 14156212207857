import React, { FC } from "react";
import { MainSectionWrapper } from "./kycSection.styles";
import MainImage from "../../../images/compliance/Compliance_3.inline.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";

const listData: CtaListProps = {
  title: ["KYC document", "verification"],
  subTitle:
    "Deliver a seamless customer experience. Forensic-level verification with granular risk adjustment AI-based selfie liveness detection.",
};

const KycSection: FC = () => (
  <section className="relative section-wrapper" css={MainSectionWrapper}>
    <div className="absolute top-0 curves-parent left-full"></div>
    <div className="relative w-full pt-24 bg-transparent wrapper-parent layout-width">
      <div className="flex flex-row flex-wrap justify-between h-full lg:mb-20">
        <div className="flex items-center justify-center w-1/2 sm:w-full">
          <CtaList {...listData} />
        </div>
        <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full">
          <div className="mx-auto">
            <MainImage />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default KycSection;
